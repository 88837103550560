import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Header } from '../components/header';
import { UseCaseSelector } from '../components/use_case_selector';
import { ContactUsFormModal } from '../components/contact_us_form';
import { Button, Grid, Segment, Container } from 'semantic-ui-react';

import { Footer } from '../components/footer';

// Won't look nice without the style-sheet.
import '../css/semantic/semantic.less';

import triangleImage from '../images/triangles.svg';

const backgroundImageStyle = {
  backgroundImage: `url(${triangleImage})`,
  backgroundSize: 'cover',
};

class IndexPage extends React.Component<{}, {}> {
  public render() {
    return (
      <>
        {/* Root container for the header background. */}
        <Container
          fluid
          style={{
            color: 'white',
            ...backgroundImageStyle,
          }}
        >
          <Header pathname="/" />
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              paddingTop: '10ex',
              paddingBottom: '3ex',
            }}
          >
            <Container>
              <Grid stackable columns={2}>
                <Grid.Column>
                  <Segment basic>
                    <h3>Mondaic</h3>
                    <h1 css={{ fontWeight: '500' }}>
                      Waveform Simulations and Inversions
                    </h1>
                    <p css={{ fontSize: '14pt', fontWeight: '300' }}>
                      We offer modern software and services for the simulation,
                      analysis, and inversion of mechanical as well as sound
                      waves across all scales.
                    </p>
                    <p css={{ fontSize: '14pt', fontWeight: '300' }}>
                      Our core product Salvus is a high-performance software
                      suite for full waveform modeling and inversion. It focuses
                      on performing fast, accurate, and flexible simulations
                      across all spatial scales, and includes a comprehensive
                      toolbox covering meshing, model building, data management
                      and full waveform inversions.
                    </p>
                  </Segment>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" centered="true">
                  <Segment textAlign="center" basic>
                    <Link to="/product">
                      <Button
                        size="large"
                        animated="fade"
                        color="violet"
                        css={{
                          boxShadow:
                            '0px 0px 37px 0px rgba(0,0,0,0.5) !important',
                          marginBottom: '1ex !important',
                          marginTop: '1ex !important',
                        }}
                      >
                        <Button.Content visible>
                          Learn more about Salvus
                        </Button.Content>
                        <Button.Content hidden>Salvus</Button.Content>
                      </Button>
                    </Link>
                    <ContactUsFormModal color="pink" size="large" />
                  </Segment>
                </Grid.Column>
              </Grid>
            </Container>
          </div>
        </Container>
        <Container
          fluid
          css={{
            color: 'white',
            backgroundColor: '#311f33',
            paddingTop: '2ex',
            paddingBottom: '2ex',
          }}
        >
          <Container textAlign="center">
            <h2 css={{ fontWeight: 700 }}>Across the Scales</h2>
            <p css={{ marginLeft: '1ex', marginRight: '1ex' }}>
              Salvus is used to study a wide range of subjects. Go{' '}
              <Link to="/applications">here</Link> for an introduction to
              possible applications.
            </p>
          </Container>
        </Container>
        <Container
          fluid
          style={{
            color: 'white',
            ...backgroundImageStyle,
          }}
        >
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              paddingBottom: '3ex',
            }}
          >
            <Container style={{ paddingTop: '1ex', paddingBottom: '1ex' }}>
              <UseCaseSelector />
            </Container>
          </div>
        </Container>
        <Container
          fluid
          style={{
            color: 'white',
            ...backgroundImageStyle,
          }}
        >
          <div
            css={{
              background:
                'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%)',
              color: 'black',
              paddingTop: '4ex',
              paddingBottom: '4ex',
              '@media only screen and (max-width: 767px)': {
                paddingLeft: `1em`,
                paddingRight: `1em`,
              },
            }}
          >
            <Container>
              <h2>
                <a name="support"></a>Partners and Support
              </h2>
              <p>
                We are very grateful for the generous support of several
                partners.
              </p>
              <p>
                Mondaic is a spin-off company of ETH Zurich and participates in
                the ESA Business Incubation Centre Switzerland.
                <br />
                We gratefully acknowledge support by Innosuisse for innovation
                project 100.599 IP-ENG.
              </p>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '2em',
                }}
              >
                <a href="https://www.innosuisse.ch" target="_blank">
                  <StaticImage
                    src="../images/logos/Innosuisse_Logo_Innovation_project_rgb_EN.png"
                    alt="Innosuisse Logo Innovation Project"
                    placeholder="tracedSVG"
                    height={150}
                  />
                </a>
                <a href="https://www.esabic.ch/" target="_blank">
                  <StaticImage
                    src="../images/logos/esa_bic_ch_logo_horizontal.png"
                    alt="ESA BIC CH Logo"
                    placeholder="tracedSVG"
                    height={150}
                  />
                </a>
                <a href="https://ethz.ch" target="_blank">
                  <StaticImage
                    src="../images/logos/spinoff-ethzuerich-label_hoch.png"
                    alt="ETH Zurich Spinoff Logo"
                    placeholder="tracedSVG"
                    height={125}
                  />
                </a>
              </div>
            </Container>
          </div>
        </Container>
        <Footer />
      </>
    );
  }
}

export default IndexPage;
